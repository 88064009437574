const initBlock = function( $block ) {
	const $soundItems = $block.find( '.sound-item' );
	if ( $soundItems.length === 0 ) { return false; }
	$soundItems.each( function( i, item ) {
		const $item = $( item );
		const $playPauseBtn = $item.find( '.sound-item__play-pause' );
		const audio = $item.find( 'audio' )[0];
		const $durationLabel = $item.find( '.sound-item__duration' );
		$playPauseBtn.on( 'click', function( e ) {
			e.preventDefault();
			if ( audio.paused ) {
				stopAllAudio();
				audio.play();
				$item.addClass( 'is-playing' );
			} else {
				audio.pause();
				$item.removeClass( 'is-playing' );
			}
		} );

		const setDuration = () => {
			const duration = audio.duration;
			if ( !isNaN( duration ) ) {
				const minutes = Math.floor( duration / 60 );
				const seconds = Math.floor( duration % 60 ).toString().padStart( 2, '0' );
				$durationLabel.text( `${minutes}:${seconds}` );
			}
		};

		if ( audio.readyState >= 1 ) {
			setDuration();
		} else {
			audio.addEventListener( 'loadedmetadata', setDuration );
		}

		setTimeout( () => {
			if ( $durationLabel.text() === '' ) {
				setDuration();
			}
		}, 3000 );
	} );

	function stopAllAudio() {
		$soundItems.each( function( i, item ) {
			const $item = $( item );
			const audio = $item.find( 'audio' )[0];
			audio.pause();
			audio.currentTime = 0;
			$item.removeClass( 'is-playing' );
		} );
	}

	const scrollContainer = document.querySelector( '.wp-site-blocks' );
	if ( scrollContainer ) {
		const observer = new IntersectionObserver( ( entries ) => {
			entries.forEach( entry => {
				if ( !entry.isIntersecting ) {
					stopAllAudio();
				}
			} );
		}, {
			root: scrollContainer,
			threshold: 0.05
		} );

		const target = $block[0];
		if ( target ) {
			observer.observe( target );
		}
	}
};

themeUtils.loadBlock( initBlock, 'messaging-library', '.section-messaging-library' );
